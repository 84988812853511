<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <b-tabs>
        <b-tab title="List of Contacts">
          <div v-if="isList" class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <h5 class="card-title">{{ pageTitle }} List</h5>
                </div>
                <div class="col-md-9">
                  <div class="row justify-content-end">
                    <div class="col-12 col-sm-4 mb-2 mb-sm-0">
                      <VSelect
                        v-model="filterStatus"
                        placeholder="-- All Status --"
                        :options="mrStatus"
                        :reduce="(v) => v.contact_status"
                        label="contact_status_description"
                      >
                      </VSelect>
                    </div>
                    <div class="col-12 col-sm-5 mb-2 mb-sm-0">
                      <form @submit.prevent="doFilter()">
                        <div class="form-group mb-0">
                          <div class="input-group">
                            <input
                              v-model="filter.search"
                              type="text"
                              class="form-control"
                              placeholder="Type keyword then enter..."
                            />
                            <div class="input-group-append">
                              <button class="btn btn-info" type="submit">
                                <i class="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-12 col-sm-2 col-lg-auto">
                      <b-button @click="doReset" class="btn btn-info btn-block"
                        >Reset</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row mt-4">
                <div class="table-responsive">
                  <table class="table product-overview table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Email Address</th>
                        <th>Inbox</th>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
                      <tr v-for="(v,k) in data.data" :key="k">
                        <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                        
                        <td>{{ v.contact_full_name }}</td>
                        <td>{{ v.contact_email_address }}</td>
                        <td>{{ v.contact_receiving_email }}</td>
                        <td>{{ v.contact_subject }}</td>
                        <td>{{ v.contact_date | moment('MMM DD, YYYY hh:mm A') }}</td>
                        <td>
                          <LabelStatus type="contact" :status="v[statusKey]" />
                        </td>

                        <td>
                          <a
                            href="javascript:;"
                            class="text-inverse icon_action act_icon"
                            v-tooltip="'Detail'"
                            @click.prevent="showModalDetail(v)"
                          >
                            <i class="fas fa-eye"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <div
                    class="pagination-flat float-right"
                    v-if="data.data !== false && data.data.length"
                  >
                    <Pagination
                      :data="data"
                      :limit="2"
                      @pagination-change-page="doPaginate"
                    >
                      <span slot="prev-nav"
                        >Previous</span>
                      <span slot="next-nav"
                        >Next</span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Email Routing">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-10">
                  <h5 class="card-title">JRF Email Routing</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ValidationObserver 
                    v-slot="{ handleSubmit }" 
                    :ref="`VFormEmailRouting`"
                  >
                    <form
                      @submit.prevent="
                        handleSubmit(
                          updateContent(
                            'email-routing',
                            emailRouting,
                            `VFormEmailRouting`,
                            false,
                            false
                          )
                        )
                      "
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Participation
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == `emailRouting`">
                                  <b-form-input
                                    v-model="emailRouting.participation"
                                    placeholder="e.g. info@marathon.id"
                                  ></b-form-input>
                                  <VValidate
                                    name="Participation"
                                    v-model="emailRouting.participation"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <p class="font-weight-bold">{{ emailRouting.participation }}</p>
                                </template>
                              </div>
                            </div>
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Sponsorship &amp; Partnership
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == `emailRouting`">
                                  <b-form-input
                                    v-model="emailRouting.sponsorship"
                                    placeholder="e.g. info@marathon.id"
                                  ></b-form-input>
                                  <VValidate
                                    name="Sponsorship"
                                    v-model="emailRouting.sponsorship"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <p class="font-weight-bold">{{ emailRouting.sponsorship }}</p>
                                </template>
                              </div>
                            </div>
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Others
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == `emailRouting`">
                                  <b-form-input
                                    v-model="emailRouting.inquiry"
                                    placeholder="e.g. info@marathon.id"
                                  ></b-form-input>
                                  <VValidate
                                    name="Inquiry"
                                    v-model="emailRouting.inquiry"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <p class="font-weight-bold">{{ emailRouting.inquiry }}</p>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="moduleRole('Edit')">
                        <div class="col-12">
                          <hr />
                          <div class="text-right">
                            <template v-if="editFormId == `emailRouting`">
                              <button
                                type="button"
                                @click="editFormId = ''"
                                class="btn btn-rounded btn-light mr-2"
                              >
                                Cancel
                              </button>
                              <button type="submit" class="btn btn-rounded btn-primary">
                                Save
                              </button>
                            </template>
                            <template v-else>
                              <button
                                type="button"
                                @click="editFormId = `emailRouting`"
                                class="btn btn-rounded btn-primary"
                              >
                                Ubah
                              </button>
                            </template>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <template v-for="(vlang,klang) in lang">
          <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
            <bo-card title="Hero Image" footer-tag="footer">
              <b-row class="gutter-2">
                <b-col md="3">
                  <bo-card-img
                    :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                    :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                    :isTitleHtml="true"
                  >
                    <template #buttons>
                      <b-button
                        v-if="moduleRole('Edit')"
                        variant="secondary"
                        size="sm"
                        pill
                        @click="editHeroImage(content['hero_' + vlang.value])"
                        v-b-tooltip.hover="'Edit'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
            </bo-card>
          </b-tab>
        </template>
        <template v-for="(vlangseo,klangseo) in lang">
          <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver 
                      v-slot="{ handleSubmit }" 
                      :ref="`VFormSeo${vlangseo.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'seo-setting',
                              content['seo_' + vlangseo.value],
                              `VFormSeo${vlangseo.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label :for="`seoTitle${vlangseo.value}`">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-input
                                      :id="`seoTitle${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`seoTitle${vlangseo.value}`"
                                      name="Title"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-textarea
                                      :id="`seoDescription${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      rows="5"
                                    ></b-form-textarea>
                                    <VValidate
                                      :vid="`seoDescription${vlangseo.value}`"
                                      name="Description"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      rules="required|min:10|max:500"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="moduleRole('Edit')">
                          <div class="col-12">
                            <hr />
                            <div class="text-right">
                              <template v-if="editFormId == `seo${vlangseo.value}`">
                                <button
                                  type="button"
                                  @click="editFormId = ''"
                                  class="btn btn-rounded btn-light mr-2"
                                >
                                  Cancel
                                </button>
                                <button type="submit" class="btn btn-rounded btn-primary">
                                  Save
                                </button>
                              </template>
                              <template v-else>
                                <button
                                  type="button"
                                  @click="editFormId = `seo${vlangseo.value}`"
                                  class="btn btn-rounded btn-primary"
                                >
                                  Ubah
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </template>
      </b-tabs>

      <b-modal id="modalDetail" size="md" title="Message Detail">
        <template #default>
          <div class="col-md-12">
            <p class="label_title">Full Name</p>
            <p>{{ contactDetail.contact_full_name }}</p>
          </div>
          <div class="col-md-12">
            <p class="label_title">Email Address</p>
            <p>{{ contactDetail.contact_email_address }}</p>
          </div>
          <div class="col-md-12">
            <p class="label_title">Inbox</p>
            <p>{{ contactDetail.contact_receiving_email }}</p>
          </div>
          <div class="col-md-12">
            <p class="label_title">Phone Number</p>
            <p>{{ contactDetail.contact_phone || '-' }}</p>
          </div>
          <div class="col-md-12">
            <p class="label_title">Subject</p>
            <p>{{ contactDetail.contact_subject }}</p>
          </div>
          <div class="col-md-12">
            <p class="label_title">Date</p>
            <p>
              {{ contactDetail.contact_date | moment('MMM DD, YYYY') }}
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <p class="label_title">Message :</p>
            <p>{{ contactDetail.contact_message }}</p>
          </div>
        </template>

        <template #modal-footer="{ hide }">
          <b-button variant="outline-secondary" @click="hide()">
            Close
          </b-button>
        </template>
      </b-modal>

      <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
        <b-modal
          id="heroImageModal"
          size="md"
          title="Edit Hero Image"
          @ok.prevent="
            handleSubmit(
              updateContent(
                'hero-image',
                inputHeroImage,
                'VFormHeroImage',
                'heroImageModal'
              )
            )
          "
        >
          <template #default>
            <div class="infoHTML"></div>
            <b-form 
              @submit.prevent="
                  handleSubmit(
                    updateContent(
                      'hero-image',
                      inputHeroImage,
                      'VFormHeroImage'
                    )
                  )
                "
            >
              <b-row>
                <b-col md="12">
                  <BoField 
                    label="Sub Title"
                    placeholder="e.g. CONTACT"
                    v-model="((inputHeroImage || {}).value || {}).sub_title"
                    :rules="mrValidation.sub_title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Title"
                    placeholder="e.g. Jakarta Running Festival 2024"
                    v-model="((inputHeroImage || {}).value || {}).title"
                    :rules="mrValidation.title"
                  />
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_image"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img"
                      :rules="mrValidation.hero_image.img"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Small Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_small"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img_small"
                      :rules="mrValidation.hero_image.img_small"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Alt Image"
                    placeholder="e.g. Hero Image"
                    v-model="((inputHeroImage || {}).value || {}).alt_img"
                    :rules="mrValidation.alt_img"
                  />
                </b-col>
                <hr />
              </b-row>
            </b-form>
          </template>

          <template #modal-footer="{ ok, cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="ok()">
              Submit
            </b-button>
          </template>
        </b-modal>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import assign from "lodash/assign";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'

import Gen from '@libs/Gen.js';

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    BoCard,
    BoCardImg
  },
  data() {
    return {
      emailRouting: {
        inquiry: '',
        collaboration: '',
        sponsorship: '',
        participation: '',
        partnership: '',
      },
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      idKey: "contact_id",
      statusKey: "contact_status",
      editor: ClassicEditor,
      contactDetail: {},
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },
      editFormId: "",
      mrStatus: [],
      content: [],
      inputHeroImage: {}
    };
  },
  computed: {
    filterStatus: {
      get() {
        return this.filter.status;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          status: newValue,
        };
        this.doFilter();
      },
    },
  },
  methods: {
    setContactStatus(id, cb) {
      Gen.apiRest(`/get/${this.modulePage}/${id}`, {}, 'GET').then((resp) => {
        if (resp.status === 200) {
          cb('success');
        }
      });
    },

    showModalDetail(v) {
      this.contactDetail = v;
      this.$bvModal.show('modalDetail');

      const { contact_id, contact_status } = v;

      if (contact_status === 'U') {
        const { data } = this.data;
        const index = data.findIndex((c) => c.contact_id === contact_id);

        if (index !== -1) {
          this.setContactStatus(contact_id, (type) => {
            if (type === 'success') {
              this.$set(this.data.data, index, {
                ...data[index],
                contact_status: 'R',
              });
            }
          });
        }
      }
    },

    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route: {
      handler() {
        this.apiGet();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>